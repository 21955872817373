<template>
     <!-- 待审核 -->
    <div class="content fs14 bs_b pl24 pr24 pt1 pb24">
        <div class="title">供应商信息</div>
        <div class="status-box mb20">
            <div class="mb10">
                <span class="sub-name">账号状态：</span>
                <span  class="fs16 c1991FC">启用</span>
            </div>

            <div class="mb10">
                <span class="sub-name">供应商账户：{{form.userName}}</span>
                <span class="sub-name ml50 mr50">供应商名称：{{form.companyName}}</span>
                <span class="sub-name">供应商编码：{{form.supplyCode}}</span>
            </div>
        </div>

        <!-- <div class="title">供应商详情</div> -->
        <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="1"></el-tab-pane>
            <el-tab-pane label="类目信息" name="2"></el-tab-pane>
            <el-tab-pane label="品牌授权" name="3"></el-tab-pane>
        </el-tabs>
        <!-- 基本信息 -->
        <div v-if="activeName =='1'">
            <div class="module header bs_b pl24 pb20 pt20 pr24">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/titleIcon.png" alt="">
                <div class="title-text">联系人信息</div>
            </div>
            <div class="field-box flex">
                <div>
                    <span class="field-name">联系人电话：</span>
                    <span class="field-main">{{form.mobile}}</span>
                </div>
                <div>
                    <span class="field-name">联系人邮箱：</span>
                    <span class="field-main">{{form.email}}</span>
                </div>
            </div>
        </div>

        <div class="module bs_b pl24 pb20 pt20 pr24">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/titleIcon.png" alt="">
                <div class="title-text">企业信息</div>
            </div>
            <div class="field-box flex flex_wrap">
                <div class="field-item">
                    <span class="field-name">企业名称：</span>
                    <span class="field-main">{{form.companyName}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">统一社会信用代码：</span>
                    <span class="field-main">{{form.socialUnifiedIdentityCreditCode}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">执照类型：</span>
                    <span class="field-main">多证合一营业执照（统一社会信用代码）</span>
                </div>
                <div class="field-item">
                    <span class="field-name">营业执照所在地：</span>
                    <span class="field-main">{{form.licenseLocationName}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">住所：</span>
                    <span class="field-main">{{form.residence}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">成立日期：</span>
                    <span class="field-main">{{form.companyCreateDate}}</span>
                </div>
                <div  class="field-item">
                    <span class="field-name">营业期限：</span>
                    <span v-if="form.businessTermStatus != 1" class="field-main">{{form.businessBeginTerm}} 至 {{form.businessEndTerm}}</span>
                    <span v-else class="field-main">长期有效</span>
                </div>
                <div  class="field-item">
                    <span class="field-name">注册资本：</span>
                    <span class="field-main">{{form.registeredCapital}}万元</span>
                </div>
                <div  class="field-item flex w100">
                    <div class="field-name">经营范围：</div>
                    <div class="field-main flex1">{{form.natureBusiness}}</div>
                </div>
                <div  class="field-item flex">
                    <span class="field-name">营业执照：</span>
                    <img class="field-img" @click="dialogImageUrlClick(form.buslicenseFileBOlist[0].filePath)" :src="form.buslicenseFileBOlist[0].filePath" alt="">
                </div>
                <div class="field-line mb23"></div>
                <div  class="field-item flex w100">
                    <div class="field-name">法定代表人证件类型：</div>
                    <div class="field-main flex1">{{form.credentialsType == 1? '中国大陆身份证' : ''}}</div>
                </div>
                <div class="field-item">
                    <span class="field-name">证件所有人姓名：</span>
                    <span class="field-main">{{form.credentialsName}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">证件号码：</span>
                    <span class="field-main">{{form.credentialsNumber}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">证件有效期：</span>
                    <span v-if="form.credentialsValidityStatus != 1" class="field-main">{{form.credentialsValidityBeginDate}} 至 {{form.credentialsValidityEndDate}}</span>
                    <span v-else class="field-main">长期有效</span>
                </div>
                <div class="field-item flex w100" style="vertical-align: top;">
                    <span class="field-name">法定代表人证件图片：</span>
                    <img class="field-img mr10"  @click="dialogImageUrlClick(form.idCardFileBOlist[0].filePath)" :src="form.idCardFileBOlist[0].filePath" alt="">
                    <img class="field-img" @click="dialogImageUrlClick(form.idCardFileBOlist[0].filePath)" :src="form.idCardFileBOlist[1].filePath" alt="">
                </div>

            </div>

        </div>
            
        </div>
        <!-- 类目信息 -->
        <div  v-if="activeName =='2'">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/titleIcon.png" alt="">
                <div class="title-text">类目信息</div>
            </div>
            <div>
                <span class="field-main" style="margin-right: 0;">{{form.commodCategoryName}}</span>
            </div>
        </div>
        <!-- 品牌授权 -->
        <div v-if="activeName =='3'">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/titleIcon.png" alt="">
                <div class="title-text">签约品牌</div>
            </div>
            <div class="add-brand cp mb20" @click="addBrand">添加品牌</div>
            <ul class="fs12 flex flex_wrap">
                <li class="authorize-list-item bs_b pt16 pl20 pr13 flex f_jc_b mb20 mr20" v-for="(item, i) in authorizedData" :key="i">
                    <img class="authorize-list-logo" :src="item.logoUrl" alt="">
                    <div>
                        <div class="flex f_jc_b mb5">
                            <div class="authorize-title by1 fs14">{{item.brandName}}</div>
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="cancelAuthorize(item)"
                                    title="是否确认取消授权?"
                                    >
                                    <span slot="reference" class="cp ml10" style="color: #FCAC19; cursor: pointer">删除</span>
                                </el-popconfirm>
                            
                        </div>
                        <div class="mt10 mb10" style="">
                            <div style="color: #AAAAAB;" class="">签约有效期至 {{item.authDate}}</div>
                            <div style="color: #AAAAAB;" class="mt5" v-if="item.cretAuthFileList.length <= 0">未上传品牌授权书</div>
                        </div>
                        <div  v-if="item.cretAuthFileList.length > 0">
                            <div class="c308FE5 mb5">
                                <i class="el-icon-s-management mr5"></i>
                                <span>品牌授权书</span>
                            </div>
                            <div>
                                <img class="img mr5 cp" @click="dialogImageUrlClick(subItem)" v-for="(subItem, j) in item.cretAuthFileList" :key="j" :src="subItem" alt="">
                            </div>
                        </div>    
                        
                        <!-- <div style="color: #C2C2C2;">已授权供应商 4 家</div> -->
                        <!-- <div class="mt20 mb7" style="color: #595A5B">签约有效期至</div>
                        <el-date-picker
                            v-model="item.authDate"
                            type="date"
                            placeholder="选择日期"
                            @change="selectDate(item)"
                            >
                        </el-date-picker> -->
                    </div>
                    
                </li>
            </ul>
        </div>
        <!-- <div class="zw mt50"></div>
        <div class="btn-box fs14 flex f_jc_c f_ai_c">
            <div class="return cp"  @click="returnGo">返回</div>
            <div class="reject cp"  @click="reject">下架</div>
            <div class="pass cp"  @click="pass">上架</div>
        </div> -->
        <el-dialog title="预览" :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

        <el-dialog title="添加品牌" :visible.sync="addDialogVisible">
            <div style="width: 40%">
                <el-input placeholder="请输入内容" v-model="brandName" class="input-with-select">
                    <el-button slot="append" @click="searchBrand" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <ul class="fs14">
                <li class="authorize-item flex f_ai_c f_jc_b" v-for="(item, i) in allBrand" :key="i" >
                    <div class="flex f_ai_c">
                        <img class="authorize-logo mr10" :src="item.logoUrl" alt="">
                        <div>{{item.brandName}}</div>
                    </div>
                    <div class="authorize-btn cp" v-if="item.isCretAuth == 0" @click="toAuthorize(item)">授权</div>
                    <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="cancelAuthorize(item)"
                                    title="是否确认取消授权?"
                                    >
                                    <div slot="reference" class="authorize-btn cp authorize-btn-q" v-if="item.isCretAuth == 1">取消授权</div>
                                </el-popconfirm>
                    
                </li>
            </ul>
        </el-dialog>
    </div>
   
</template>

<script>
import moment from 'moment'
export default {
    name: '',
    data(){
        return{
            authDate: '',
            dateTime: '',
            input: '',
            dialogVisible: false,
            dialogImageUrl: '',
            activeName: this.$route.query.type + '',
            tableData: [],
            userId: this.$route.query.userId,
            id: this.$route.query.id,
            form: {},
            addDialogVisible: false,
            authorizedData: [], //已经授权列表
            allBrand: [], //所有列表
            brandName: '',
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            // 获取供应商详情
            this.qa.queryUniqueObject({userId: this.userId}).then(res => {
                if(res.respCode == "0000") {
                    this.form = res.data
                    this.form.companyCreateDate = moment(this.form.companyCreateDate).format('YYYY-MM-DD')
                    this.form.businessBeginTerm = moment(this.form.businessBeginTerm).format('YYYY-MM-DD')
                    this.form.businessEndTerm = moment(this.form.businessEndTerm).format('YYYY-MM-DD')
                    this.form.credentialsValidityBeginDate = moment(this.form.credentialsValidityBeginDate).format('YYYY-MM-DD')
                    this.form.credentialsValidityEndDate = moment(this.form.credentialsValidityEndDate).format('YYYY-MM-DD')
                } else {
                    this.$message.error(res.msg);
                }
            })
            this.getBrandIdList ()
        },
        // 查询已授权列表
        getBrandIdList () {
            this.qa.queryCommodityBrandIdListByShopId({shopId: this.id}).then(res => {
                if(res.respCode == "0000") {
                    if(res.data.length >  0) {
                        // var authorizedData = res.data
                        // for (var i=0; i<this.authorizedData.length; i++) {
                        //     authorizedData[i].authDate = ''
                        // }
                        this.authorizedData = res.data
                        for(var i=0; i<this.authorizedData.length; i++) {
                            if(this.authorizedData[i].authDate == null) {
                                this.authorizedData[i].authDate = ''
                            }else {
                                this.authorizedData[i].authDate = moment(this.authorizedData[i].authDate).format('YYYY-MM-DD')
                            }
                            if(this.authorizedData[i].cretAuthFile !=null && this.authorizedData[i].cretAuthFile != '') {
                                this.authorizedData[i].cretAuthFileList = this.authorizedData[i].cretAuthFile.split('|')
                            }else {
                                this.authorizedData[i].cretAuthFileList = []
                            }
                        }
                        console.log(this.authorizedData)
                    }

                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        returnGo() {
            this.$router.push('supplierApproval');
        },
        dialogImageUrlClick(url) {
            this.dialogVisible = true
            this.dialogImageUrl = url
        },
        //取消授权
        cancelAuthorize(data) {
            this.qa.doBatchLogicDelete({shopId: this.id, brandId: data.id, authDate: ''}).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '已取消授权',
                        type: 'success'
                    });
                    this.getBrandList();
                    this.getBrandIdList ();
                } else {
                    this.$message.error(res.msg);
                }
            })
            
        },
        //去授权
        toAuthorize(data) {
            this.qa.authorizeDoInsert({shopId: this.id, brandId: data.id, authDate: ''}).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '授权成功',
                        type: 'success'
                    });
                    this.getBrandList();
                    this.getBrandIdList ();
                } else {
                    this.$message.error(res.msg);
                }
            })
            
            console.log(item)
        },
        tableHeaderColor({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'background-color: #F9F9F9;font-weight: 500;'
            }
        },
        // 添加品牌列表
        addBrand() {
            this.addDialogVisible = true;
            this.getBrandList()
        },
        // 弹框中搜索品牌
        searchBrand() {
            this.getBrandList()
        },
        // 获取所有品牌列表
        getBrandList() {
            this.qa.queryCommodityBrandList({shopId: this.id, brandName: this.brandName}).then(res => {
                if(res.respCode == "0000") {
                    if(res.data.length > 0) {
                        this.allBrand = res.data
                    }
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        selectDate(data) {
            this.qa.doUpdateAuthDateByShopIdAndbrandId({shopId: this.id, brandId: data.id, authDate: data.authDate}).then(res => {
                if(res.respCode == "0000") {
                    
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style scoped>
    .title {
        font-size: 14px;
        color: #1D1E1F;
        margin: 24px 0 10px;
        font-weight: 600;
    }
    .status-box {
        width: 100%;
        background: rgba(25, 145, 252, 0.02);
        border-radius: 2px;
        border: 1px solid rgba(0, 145, 255, 0.2);
        box-sizing: border-box;
        padding: 24px;
    }

    .content >>> .el-tabs .is-active {
        background: rgba(25, 145, 252, 0.1);
    }
    .content >>> .el-tabs .el-tabs__item {
        /* padding: 0; */
        

    }
    .content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        padding-left: 20px;
    }
    .content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
        padding-right: 20px;
    }

    .basic-name {
        font-size: 12px;
        color: #9B9B9C;
        margin-bottom: 6px;
    }
    .basic-main {
        color: #1D1E1F;
        margin-bottom: 10px;
    }

    .commodity-img {
        width: 120px;
        height: 120px;
    }
    .zw {
        width: 100%;
        height: 44px;
    }
    .btn-box {
        position: fixed;
        bottom: 0;
        box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.06);
        width: 100%;
        height: 44px;
        background: #fff;
        z-index: 5;
        margin-left: -24px;
    }
    .btn-box div {
        width: 83px;
        text-align: center;
        height: 32px;
        line-height: 32px;
        box-sizing: border-box;    
    }
    .return {
        color: #A4A5A5;
        border: 1px solid #A4A5A5;
        background: #fff;
    }
    .reject {
        border: 1px solid #F53333;
        background: #F53333;
        margin: 0 15px;
        color: #fff;
    }
    .pass {
        border: 1px solid #1991FC;
        background: #1991FC;
        color: #fff;
    }
    .module {
        background: #fff;
        margin-bottom: 20px;
    }
        .field-name {
        font-size: 14px;
        color: #A4A4A5;
    }
    .field-main {
        font-size: 14px;
        color: #1D1E1F;
        margin-right: 50px;
    }
    .field-item {
        margin-bottom: 15px;
    }
    .field-img {
        width: 80px;
        height: 60px;
    }
    .field-line {
        width: 100%;
        height: 1px;
        background: #EEEEEE;
    }
    .title-icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        color: #1D1E1F;
        font-size: 16px;
    }
    .title-box {
        margin-bottom: 20px;
    }
    .add-brand {
        width: 111px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #308FE5;
        color: #308FE5;
    }
    .authorize-btn {
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #1991FC;
        color: #1991FC;
    }
    .authorize-btn-q {
        border: 1px solid #E4E4E4;
        color: #8C8C8D;
    }

    .authorize-logo {
        width: 38px;
        max-height: 38px;
    }
    .authorize-item {
        width: 100%;
        height: 62px;
    }

    .authorize-list-item {
        width: 530px;
        height: 148px;
        background: #FAFAFA ;
    }
    .authorize-title {
        width: 407px;
    }
    .authorize-list-logo {
        width: 40px;
        max-height: 40px;
    }
    .img {
        width: 60px;
        height: 40px;
        border-radius: 2px;
    }
    
</style>